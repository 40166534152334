import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { SupportedLanguages } from "../utils/supportedLanguages";

export default function LanguageSelector() {
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [displayLanguage, setDisplayLanguage] = useState(
    getCurrentDisplayLanguage()
  );
  const { t } = useTranslation();

  function getCurrentDisplayLanguage() {
    return SupportedLanguages.find(({ code }) => code === i18next.languages[0])
      ?.name;
  }

  useEffect(() => {
    const handler = (data: any) => {
      setDisplayLanguage(data.detail.lang);
    };

    document.addEventListener("displayLanguageChanged", handler);

    return () => {
      document.removeEventListener("displayLanguageChanged", handler);
    };
  }, []);

  function setLanguage(lang: string) {
    i18next.changeLanguage(lang);
  }

  const handleLanguageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = (
    event: React.MouseEvent<HTMLSelectElement>
  ) => {
    setLanguageMenuAnchorEl(null);
  };
  const handleLanguageItemClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setDisplayLanguage(event.currentTarget.innerText);
    document.dispatchEvent(
      new CustomEvent("displayLanguageChanged", {
        detail: { lang: event.currentTarget.innerText },
      })
    );
    setLanguageMenuAnchorEl(null);
    const language = event.currentTarget.getAttribute("value");
    if (language != null) {
      setLanguage(language);
    }
  };

  return (
    <div>
      <Button
        id="language-button"
        aria-controls={languageMenuAnchorEl ? "language-button" : undefined}
        aria-haspopup="true"
        aria-expanded={languageMenuAnchorEl ? "true" : undefined}
        onClick={handleLanguageButtonClick}
        variant="contained"
      >
        <p>
          {t("lang")}: {displayLanguage}
          <br />
          <small>{t("lang_clickToChange")}</small>
        </p>
      </Button>
      <Menu
        id="language-menu"
        anchorEl={languageMenuAnchorEl}
        open={!!languageMenuAnchorEl}
        onClose={handleLanguageMenuClose}
        MenuListProps={{
          "aria-labelledby": "language-menu",
        }}
      >
        {Array.from(SupportedLanguages).map((lang) => (
          <MenuItem
            key={`language-menu-item-${lang.code}`}
            onClick={handleLanguageItemClick}
            value={lang.code}
          >
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
