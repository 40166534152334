import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { IMissionFollowup } from "../models/IMissionFollowup";
import { IUser } from "../models/IUser";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import { englishStringTranslator } from "@rjsf/utils";
import { TranslatableString } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/mui";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import ConsultantIcon from "@mui/icons-material/Person";
import ManagerIcon from "@mui/icons-material/ManageAccounts";
import moment from "moment";

export function ClientFollowupFiller() {
  const { token } = useParams();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [followup, setFollowup] = useState<IMissionFollowup | null>(null);
  const [consultant, setConsultant] = useState<IUser | null>(null);
  const [manager, setManager] = useState<IUser | null>(null);

  const [clientSchemaOriginal, setClientSchemaOriginal] = useState<any>({});
  const [clientUiSchema, setClientUiSchema] = useState<any>({});
  const [formClientData, setFormClientData] = useState<any>({});

  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [closed, setClosed] = useState(false);

  const translateForm = useCallback(
    (formData: any) => {
      const tmp = JSON.parse(JSON.stringify(formData));
      if (i18n.resolvedLanguage !== "fr") {
        Object.keys(tmp).forEach((key) => {
          if (formData[key]) {
            if (key.endsWith("-en")) {
              tmp[key.replace("-en", "")] = tmp[key];
            } else if (typeof tmp[key] === "object") {
              tmp[key] = translateForm(tmp[key]);
            }
          }
        });
      }
      return tmp;
    },
    [i18n.resolvedLanguage]
  );

  const hideBMFields = useCallback((uiSchema: any) => {
    Object.keys(uiSchema).forEach((key) => {
      if (uiSchema[key]) {
        if (key.endsWith("_bm")) {
          uiSchema[key]["ui:widget"] = "hidden";
        } else if (typeof uiSchema[key] === "object") {
          uiSchema[key] = hideBMFields(uiSchema[key]);
        }
      }
    });
    return uiSchema;
  }, []);

  const saveFormData = useCallback(
    (patch: any) => {
      setLoading(true);

      axios
        .patch("/api/v1/followups-client/" + token, patch)
        .then((ret) => {
          setFollowup(ret.data.followup);
          setConsultant(ret.data.consultant);
          setManager(ret.data.manager);
          setClientSchemaOriginal(ret.data.followup.formClientTemplate);
          setClientUiSchema(
            hideBMFields({
              ...ret.data.followup.formClientTemplate.uiSchema,
              "ui:order": ret.data.followup.formClientTemplate.order,
            })
          );
          setSaved(true);
          setError(false);
          setClosed(ret.data.followup.closed);
        })
        .catch((e) => {
          console.log(e);
          setSaved(false);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    [token]
  );

  useEffect(() => {
    (async () => {
      try {
        const ret = await axios.get<{
          consultant: IUser;
          followup: IMissionFollowup;
          manager: IUser;
        }>(`/api/v1/followups-client/${token}`);
        setConsultant(ret.data.consultant);
        setFollowup(ret.data.followup);
        setManager(ret.data.manager);

        setClientSchemaOriginal(ret.data.followup.formClientTemplate);
        setClientUiSchema(
          hideBMFields({
            ...ret.data.followup.formClientTemplate.uiSchema,
            "ui:order": ret.data.followup.formClientTemplate.order,
          })
        );
        setFormClientData(ret.data.followup.formClientData);
        setClosed(ret.data.followup.closed);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [token]);

  const clientSchema = useMemo(() => {
    return translateForm(clientSchemaOriginal);
  }, [clientSchemaOriginal, i18n.resolvedLanguage]);

  const translateString = useCallback(
    (stringToTranslate: TranslatableString, params?: string[]) => {
      if (stringToTranslate === TranslatableString.YesLabel) {
        return t("yes");
      }
      if (stringToTranslate === TranslatableString.NoLabel) {
        return t("no");
      }
      return englishStringTranslator(stringToTranslate, params);
    },
    [t]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      p={2}
      sx={{
        backgroundColor: "rgb(255, 239, 224)",
        minHeight: "100vh",
      }}
    >
      <img
        src="/assets/images/logo-klanik.png"
        alt="Klanik Logo"
        style={{ maxWidth: "100%", width: "300px" }}
      />

      <LanguageSelector />

      <Paper
        sx={{
          maxWidth: "980px",
          backgroundColor: "var(--form-paper-background)",
          color: "inherit",
          textAlign: "center",
        }}
      >
        <Box p={3}>
          <Typography component="h1" sx={{ mb: 2, fontSize: "1.75rem" }}>
            {t("client-followup-filler.welcome_title")}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {t("client-followup-filler.welcome_message")}
          </Typography>
        </Box>
      </Paper>

      <Paper>
        <Box
          p={2}
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-around",
            flexDirection: { lg: "row", xs: "column" },
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <CalendarIcon />
            {t("client-followup-filler.date")}:{" "}
            {moment(followup?.followupDate).format(t("date_format"))}
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ConsultantIcon />
            {t("client-followup-filler.consultant")}: {consultant?.displayName}
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ManagerIcon />
            {t("client-followup-filler.manager")}: {manager?.displayName}
          </Box>
        </Box>
      </Paper>

      {saved && (
        <Alert sx={{ mt: 2 }} severity="success">
          {t("client-followup-filler.saved")}
        </Alert>
      )}
      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          {t("client-followup-filler.error")}
        </Alert>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : closed ? (
        <Alert sx={{ mt: 2 }} severity="info">
          {t("client-followup-filler.closed")}
        </Alert>
      ) : (
        <Box maxWidth="980px">
          <Paper sx={{ backgroundColor: "white" }}>
            <Box p={2}>
              <Form
                schema={clientSchema}
                uiSchema={clientUiSchema}
                validator={validator}
                formData={formClientData}
                translateString={translateString}
                onSubmit={({ formData }) => {
                  setFormClientData(formData);
                  saveFormData({ formClientData: formData });
                }}
                disabled={closed}
              >
                <Box display="flex" justifyContent="flex-end">
                  <Button type="submit" variant="contained">
                    {t("save")}
                  </Button>
                </Box>
              </Form>
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
}
